import { Confirmation } from "../../components/Confirmation/Confirmation";

export default function Conf() {
  return (
    <>
      <Confirmation />
      <div className="hr"></div>
    </>
  );
}
